import { useStaticQuery, graphql } from "gatsby";
import opengraph from "../images/opengraph.jpg";

const SetMeta = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          baseUrl
          twitterHandle
        }
      }
    }
  `);
  let title = data.site.siteMetadata.title;
  let description = data.site.siteMetadata.description;
  let siteUrl = data.site.siteMetadata.siteUrl;
  let baseUrl = data.site.siteMetadata.baseUrl.endsWith('/') ? data.site.siteMetadata.baseUrl.slice(0, -1) : data.site.siteMetadata.baseUrl;
  let siteName = data.site.siteMetadata.siteName;
  let twitterHandle = data.site.siteMetadata.twitterHandle;

  const Meta = {
    title: title,
    description: description,
    og: {
      type: "website",
      url: siteUrl,
      title: title,
      description: description,
      image: {
        url: `${baseUrl}${opengraph}`,
        width: "1200",
        height: "630",
      },
      locale: "en_GB",
      site_name: siteName,
    },
    twitter: {
      site: twitterHandle,
      title: title,
      description: description,
      card: "summary_large_image",
    },
    canonical: siteUrl,
  };

  return Meta;
};

export default SetMeta;
