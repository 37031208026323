import { useStaticQuery, graphql } from "gatsby";

const SetSchema = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          schemaData {
            name
            legalName
            url
            logo
            foundingDate
            founders {
              name
            }
            address {
              streetAddress
              addressLocality
              addressRegion
              postalCode
              addressCountry
            }
            sameAs
          }
        }
      }
    }
  `);

  const Schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: data.site.siteMetadata.schemaData.name,
    legalName: data.site.siteMetadata.schemaData.legalName,
    url: data.site.siteMetadata.schemaData.url,
    logo: data.site.siteMetadata.schemaData.logo,
    foundingDate: data.site.siteMetadata.schemaData.foundingDate,
    founders: [
      {
        "@type": "Person",
        name: data.site.siteMetadata.schemaData.founders.name,
      },
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: data.site.siteMetadata.schemaData.address.streetAddress,
      addressLocality:
        data.site.siteMetadata.schemaData.address.addressLocality,
      addressRegion: data.site.siteMetadata.schemaData.address.addressRegion,
      postalCode: data.site.siteMetadata.schemaData.address.postalCode,
      addressCountry: data.site.siteMetadata.schemaData.address.addressCountry,
    },
    sameAs: data.site.siteMetadata.schemaData.sameAs,
  };

  return Schema;
};

export default SetSchema;
