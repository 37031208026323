import React from "react";
import { Disclosure } from "@headlessui/react";
import Logo from "../components/logo";
import SocialIcons from "../components/social-icons";
import { useStaticQuery, graphql } from "gatsby";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          baseUrl
          siteUrl
        }
      }
    }
  `);

  return (
    <Disclosure as="nav" className="bg-transparent absolute w-full z-20">
      {({ open }) => (
        <>
          <div className="container-fluid w-full m-auto py-0 px-5 md:px-10">
            <div className="relative flex items-center justify-between h-20 lg:h-20">
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center px-0 md:px-5 lg:px-0">
                  <a href={data.site.siteMetadata.baseUrl}>
                    <Logo colour="light" />
                  </a>
                </div>
                <div className="flex items-center ml-auto">
                  <div className="flex space-x-5 items-center">
                    {/* <FacebookShareButton url={data.site.siteMetadata.siteUrl} quote={data.site.siteMetadata.description}>
                      <SocialIcons type="instagram" />
                    </FacebookShareButton> */}
                    <TwitterShareButton url={data.site.siteMetadata.siteUrl} title={data.site.siteMetadata.title} via={data.site.siteMetadata.twitterHandle} related={data.site.siteMetadata.twitterHandle}>
                      <SocialIcons type="twitter" />
                    </TwitterShareButton>
                    <FacebookShareButton url={data.site.siteMetadata.siteUrl} quote={data.site.siteMetadata.description}>
                      <SocialIcons type="facebook" />
                    </FacebookShareButton>
                    <LinkedinShareButton url={data.site.siteMetadata.siteUrl} title={data.site.siteMetadata.title} summary={data.site.siteMetadata.description} source="Upgraded Points">
                      <SocialIcons type="linkedin" />
                    </LinkedinShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1"></div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
