import React from "react";

const socialIcons = ({ type, colour }) => {
  const theme = colour === "dark" ? "#08466B" : "#FFF";

  switch (type) {
    case "instagram":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.254"
          height="22.249"
          viewBox="0 0 22.254 22.249"
        >
          <path
            id="Icon_awesome-instagram"
            data-name="Icon awesome-instagram"
            d="M11.124,7.658a5.7,5.7,0,1,0,5.7,5.7A5.7,5.7,0,0,0,11.124,7.658Zm0,9.413a3.709,3.709,0,1,1,3.709-3.709,3.715,3.715,0,0,1-3.709,3.709Zm7.268-9.646a1.331,1.331,0,1,1-1.331-1.331A1.327,1.327,0,0,1,18.392,7.424Zm3.778,1.35a6.584,6.584,0,0,0-1.8-4.662,6.628,6.628,0,0,0-4.662-1.8c-1.837-.1-7.343-.1-9.18,0A6.618,6.618,0,0,0,1.87,4.108,6.606,6.606,0,0,0,.073,8.77c-.1,1.837-.1,7.343,0,9.18a6.584,6.584,0,0,0,1.8,4.662,6.636,6.636,0,0,0,4.662,1.8c1.837.1,7.343.1,9.18,0a6.584,6.584,0,0,0,4.662-1.8,6.628,6.628,0,0,0,1.8-4.662c.1-1.837.1-7.338,0-9.175ZM19.8,19.92a3.755,3.755,0,0,1-2.115,2.115c-1.465.581-4.94.447-6.558.447s-5.1.129-6.558-.447A3.755,3.755,0,0,1,2.451,19.92C1.87,18.456,2,14.981,2,13.362s-.129-5.1.447-6.558A3.755,3.755,0,0,1,4.566,4.689c1.465-.581,4.94-.447,6.558-.447s5.1-.129,6.558.447A3.755,3.755,0,0,1,19.8,6.8c.581,1.465.447,4.94.447,6.558S20.378,18.461,19.8,19.92Z"
            transform="translate(0.005 -2.238)"
            fill={theme}
          />
        </svg>
      );
    case "facebook":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.719"
          height="21.882"
          viewBox="0 0 11.719 21.882"
        >
          <path
            id="Icon_awesome-facebook-f"
            data-name="Icon awesome-facebook-f"
            d="M12.561,12.308l.608-3.96h-3.8V5.779A1.98,1.98,0,0,1,11.6,3.639h1.727V.268A21.066,21.066,0,0,0,10.263,0C7.133,0,5.088,1.9,5.088,5.33V8.348H1.609v3.96H5.088v9.573H9.369V12.308Z"
            transform="translate(-1.609)"
            fill={theme}
          />
        </svg>
      );
    case "twitter":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.882"
          height="17.772"
          viewBox="0 0 21.882 17.772"
        >
          <path
            id="Icon_awesome-twitter"
            data-name="Icon awesome-twitter"
            d="M19.632,7.81c.014.194.014.389.014.583a12.672,12.672,0,0,1-12.76,12.76A12.673,12.673,0,0,1,0,19.139a9.278,9.278,0,0,0,1.083.056,8.981,8.981,0,0,0,5.568-1.916,4.493,4.493,0,0,1-4.193-3.11,5.656,5.656,0,0,0,.847.069,4.743,4.743,0,0,0,1.18-.153,4.485,4.485,0,0,1-3.6-4.4V9.629a4.516,4.516,0,0,0,2.027.569,4.491,4.491,0,0,1-1.388-6,12.747,12.747,0,0,0,9.247,4.693,5.063,5.063,0,0,1-.111-1.027A4.489,4.489,0,0,1,18.424,4.8a8.829,8.829,0,0,0,2.846-1.083A4.473,4.473,0,0,1,19.3,6.185a8.99,8.99,0,0,0,2.582-.694A9.64,9.64,0,0,1,19.632,7.81Z"
            transform="translate(0 -3.381)"
            fill={theme}
          />
        </svg>
      );
    case "youtube":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23.34"
          height="16.411"
          viewBox="0 0 23.34 16.411"
        >
          <path
            id="Icon_awesome-youtube"
            data-name="Icon awesome-youtube"
            d="M23.9,7.068a2.933,2.933,0,0,0-2.064-2.077C20.019,4.5,12.72,4.5,12.72,4.5s-7.3,0-9.119.491A2.933,2.933,0,0,0,1.538,7.068a30.766,30.766,0,0,0-.488,5.654,30.766,30.766,0,0,0,.488,5.654A2.889,2.889,0,0,0,3.6,20.42c1.82.491,9.119.491,9.119.491s7.3,0,9.119-.491A2.889,2.889,0,0,0,23.9,18.377a30.765,30.765,0,0,0,.488-5.654A30.766,30.766,0,0,0,23.9,7.068Zm-13.57,9.125V9.252l6.1,3.471-6.1,3.47Z"
            transform="translate(-1.05 -4.5)"
            fill={theme}
          />
        </svg>
      );
    case "linkedin":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.943" height="21.942" viewBox="0 0 21.943 21.942">
          <path id="LinkedIn" d="M4.912,21.96H.362V7.31H4.912ZM2.635,5.312a2.647,2.647,0,1,1,2.635-2.66A2.657,2.657,0,0,1,2.635,5.312Zm19.3,16.649H17.4V14.829c0-1.7-.034-3.879-2.365-3.879-2.365,0-2.728,1.847-2.728,3.757V21.96H7.761V7.31h4.363v2h.064a4.78,4.78,0,0,1,4.3-2.366c4.6,0,5.451,3.032,5.451,6.97V21.96Z" transform="translate(0 -0.018)" fill={theme} />
        </svg>
      );
    default:
      return null;
  }
};

export default socialIcons;
