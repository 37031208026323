import React from "react";
import { Helmet } from "react-helmet";
import SetMeta from "../hooks/setMetadata";
import SetSchema from "../hooks/setSchema";

const Seo = ({ title, description, path }) => {
  const meta = SetMeta();
  const schema = SetSchema();

  return (
    <Helmet defer={false}>
      <meta charSet="utf-8" />
      {title ? (
        <title>{title}</title>
      ) : (
        <title>{meta.title && meta.title}</title>
      )}
      <meta name="description" content={meta.description && meta.description} />
      {meta.og.type && <meta property="og:type" content={meta.og.type} />}
      {meta.og.url && <meta property="og:url" content={meta.og.url} />}
      {meta.og.title && <meta property="og:title" content={meta.og.title} />}
      {meta.og.description && (
        <meta property="og:description" content={meta.og.description} />
      )}
      {meta.og.image.url && (
        <meta property="og:image" content={meta.og.image.url} />
      )}
      {meta.og.image.width && (
        <meta property="og:image:width" content={meta.og.image.width} />
      )}
      {meta.og.image.height && (
        <meta property="og:image:height" content={meta.og.image.height} />
      )}
      {meta.og.locale && <meta property="og:locale" content={meta.og.locale} />}
      {meta.og.site_name && (
        <meta property="og:site_name" content={meta.og.site_name} />
      )}
      {meta.twitter.site && (
        <meta name="twitter:site" content={meta.twitter.site} />
      )}
      {meta.twitter.creator && (
        <meta name="twitter:creator" content={meta.twitter.creator} />
      )}
      {meta.twitter.title && (
        <meta name="twitter:title" content={meta.twitter.title} />
      )}
      {meta.twitter.description && (
        <meta name="twitter:description" content={meta.twitter.description} />
      )}
      {meta.twitter.card && (
        <meta name="twitter:card" content={meta.twitter.card} />
      )}
      {meta.og.image.url && (
        <meta name="twitter:image" content={meta.og.image.url} />
      )}
      {path && meta.canonical ? (
        <link rel="canonical" href={meta.canonical + '/' + path} />
      ) : (
        meta.canonical && <link rel="canonical" href={meta.canonical} />
      )}


      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default Seo;
