import React from "react";

const logo = ({ colour }) => {
  const theme = colour === "dark" ? "#08466B" : "#FFF";

  return (
    <svg id="logo" x="0px" y="0px" width="150px" viewBox="0 0 2279 252">
      <g transform="translate(-.000441 .64)">
        <path d="M168.3,110.5l-0.2,0.4h0.2L168.3,110.5z" fill={theme} />
        <g>
          <path
            d="M180.9,51.2l-18.7,40.3l-0.2,0.4l-17,36.7h16.6v50.2c0,22.6-6.7,40.3-21.6,53s-34.5,19.2-58.5,19.2 c-24.3,0-43.9-6.4-59-19.2S-0.1,201.3,0,178.9L0,51.2h45.8v127.7c0,12.1,3.1,21.4,9.5,27.6s15,9.3,26.3,9.3 c11.1,0,19.7-3.1,25.9-9.2s9.2-15.4,9.2-27.7V51.2L180.9,51.2z"
            fill={theme}
          />
          <path
            d="M519.4,220.9c-6.8,8.3-16.5,15.4-28.9,21.2s-28.4,8.8-48,8.8c-24.4,0-44.3-7.5-59.5-22.5 c-15.2-15-22.9-34.4-22.9-58.3v-40.9c0-23.7,7.4-43.1,22.2-58.1s33.9-22.6,57.2-22.6c25.2,0,44.2,5.6,57,16.8 c12.8,11.2,19.1,26.6,18.6,46.1l-0.2,0.8h-42.9c0-9.7-2.5-16.8-7.7-21.5s-12.9-7-23.3-7c-10.7,0-19.2,4.2-25.7,12.5 s-9.7,19.3-9.7,32.7v41.4c0,13.7,3.2,24.7,9.7,33c6.4,8.3,15.4,12.5,27.1,12.5c8.4,0,15-0.7,19.8-2.1c4.1-1,8-2.8,11.4-5.4v-35.1 h-34.8v-30.3h80.6L519.4,220.9z"
            fill={theme}
          />
          <path
            d="M580.2,172.7v75.5h-45.7v-197H603c22.8,0,40.9,5.3,54.2,15.8c13.4,10.6,20,24.9,20,43.3 c0,10.2-2.7,18.9-8,26.3c-5.3,7.4-13.3,13.2-23.7,17.8c12,3.4,20.7,9.2,26,17.1s7.9,17.9,7.9,29.8v12.5c0.1,5.9,0.9,11.8,2.3,17.6 c1.6,6.6,4.2,11.2,8,14v2.8h-47.2c-3.6-2.8-6-7.7-7-14.7c-1.1-6.6-1.6-13.2-1.7-19.9v-11.9c0-9.4-2.5-16.5-7.4-21.5 s-12.2-7.4-21.8-7.4h-24.6V172.7z M580.2,137.5h23.1c9.2,0,16.2-2.1,21.1-6.3s7.3-10.2,7.3-18.1c0-8-2.5-14.4-7.5-19.4 s-12.1-7.3-21.1-7.3h-22.9L580.2,137.5L580.2,137.5z"
            fill={theme}
          />
          <path
            d="M822.3,209.8h-58.5l-11.4,38.2h-48.2l64.2-196.9h24.4v0.2l0.2-0.2h24.4L881.8,248h-48.1L822.3,209.8z M776.8,174.6h37.5l-18.4-61.1H795L776.8,174.6z"
            fill={theme}
          />
          <path
            d="M896.4,248.1V51.2h72.1c23.2,0,42.5,7.5,58.1,22.6s23.4,34.4,23.4,58v35.4c0,23.7-7.8,43.1-23.4,58.1 s-35,22.6-58.1,22.5h-72.1L896.4,248.1z M942.1,86.4V213h26.3c10.4,0,18.9-4.2,25.6-12.7c6.7-8.5,10.1-19.4,10.1-32.9v-35.7 c0-13.2-3.4-24.1-10.1-32.6s-15.3-12.7-25.6-12.7H942.1z"
            fill={theme}
          />
          <path
            d="M1180,163.8h-69.4v49.1h83.2v35.2h-128.8V51.2h129v35.2h-83.3v42.3h69.4V163.8z"
            fill={theme}
          />
          <path
            d="M1209.7,248.1V51.2h72.1c23.2,0,42.5,7.5,58.1,22.6s23.4,34.4,23.4,58v35.4c0,23.7-7.8,43.1-23.4,58.1 s-34.9,22.6-58.1,22.5h-72.1V248.1z M1254.1,86.4V213h26.3c10.4,0,18.9-4.2,25.6-12.7s10.1-19.4,10.1-32.9v-35.7 c0-13.2-3.4-24.1-10.1-32.6s-15.2-12.7-25.6-12.7H1254.1z"
            fill={theme}
          />
        </g>
        <g>
          <path
            d="M1425.4,180.6v67.5h-45.7V51.2h75.1c23.4,0,41.9,5.9,55.6,17.8s20.5,27.5,20.6,46.9c0,19.4-6.9,35-20.6,46.8 c-13.7,11.9-32.2,17.8-55.6,17.8h-29.5V180.6z M1425.4,145.5h29.5c9.9,0,17.5-2.7,22.7-8.2s7.8-12.5,7.8-21 c0-8.8-2.6-15.9-7.8-21.5s-12.8-8.4-22.8-8.4h-29.5L1425.4,145.5z"
            fill={theme}
          />
          <path
            d="M1714,167.4c0,24.3-7.7,44.3-23,59.9s-35.4,23.6-60.2,23.6c-24.9,0-45.2-7.9-60.7-23.6 c-15.5-15.7-23.3-35.8-23.3-59.9V132c0-24.2,7.8-44.1,23.2-59.9s35.4-23.7,60.4-23.7c24.7,0,44.8,7.9,60.3,23.7 s23.3,35.8,23.3,59.9L1714,167.4z M1668.4,131.7c0-14-3.3-25.6-9.9-34.6s-16.1-13.5-28.1-13.5c-12.4,0-21.8,4.5-28.3,13.5 s-9.8,20.6-9.8,34.7v35.7c0,14.2,3.3,25.8,9.9,34.8s16.1,13.6,28.5,13.6c12,0,21.3-4.6,27.8-13.6s9.9-20.6,9.9-34.8L1668.4,131.7 L1668.4,131.7z"
            fill={theme}
          />
          <path d="M1728.4,51.2h45.6v196.9h-45.6V51.2z" fill={theme} />
          <path
            d="M1953.6,248.1H1908l-72.1-123.9l-0.8,0.2v123.6h-45.7V51.2h45.7l72.1,123.7l0.8-0.2V51.2h45.6V248.1z"
            fill={theme}
          />
          <path
            d="M2118.4,86.4h-52V248h-45.7V86.4h-51.1V51.2h148.9L2118.4,86.4L2118.4,86.4z"
            fill={theme}
          />
          <path
            d="M2232.9,195.9c0-7-2.3-12.4-6.9-16.2s-13-7.8-25-12c-24.4-7.9-42.6-16.4-54.4-25.4 c-11.9-9-17.8-21.9-17.8-38.7c0-16.5,6.9-29.8,20.7-40c13.8-10.1,31.3-15.2,52.6-15.2c22.5,0,40.5,5.3,54.2,15.8 s20.3,25.3,19.9,44.4l-0.2,0.8h-44.4c0-9.2-2.6-16-7.6-20.2s-12.6-6.3-22.9-6.3c-8.3,0-14.9,2.1-19.6,6.1c-4.6,3.6-7.2,9.2-7.2,15 c-0.1,5.6,2.7,10.8,7.4,13.9c4.9,3.5,14.1,7.8,27.5,13c22.8,6.8,40,15,51.8,24.8c11.7,9.8,17.6,23,17.6,40.1 c0,17.3-6.8,30.9-20.3,40.6c-13.6,9.7-31.5,14.7-53.8,14.7s-41.1-5.3-56.6-15.8s-23-27-22.6-49.5l0.2-0.8h44.5 c0,11.5,2.8,19.6,8.4,24.4s14.3,7.3,25.9,7.3c9.6,0,16.7-1.9,21.4-5.7C2230.5,207.3,2233.1,201.7,2232.9,195.9"
            fill={theme}
          />
        </g>
        <path
          d="M325.3,69c-13.7-11.9-32.2-17.9-55.6-17.9h-88.8l13.7,29.6l5.1,11.1l17,36.7h-16.7V248h40.2v-67.5h29.5 c23.4,0,41.9-5.9,55.6-17.8s20.5-27.4,20.5-46.8S339,80.9,325.3,69L325.3,69z M297.6,137.3c-5.2,5.4-12.8,8.2-22.7,8.2h-29.5V86.4 h29.5c10,0,17.6,2.8,22.8,8.4c5.2,5.5,7.8,12.7,7.8,21.5C305.4,124.8,302.8,131.9,297.6,137.3L297.6,137.3z"
          fill={theme}
        />
        <path
          d="M2239.9,23.6h-5V4.2h-6.4V0h17.8v4.2h-6.4L2239.9,23.6z"
          fill={theme}
        />
        <path
          d="M2259.9,23.6l-5.7-18.5h-0.1c0.2,3.8,0.3,6.3,0.3,7.5v11h-4.5V0h6.8l5.6,18h0.1l5.9-18h6.8v23.6h-4.7v-13 c0-0.7,0.1-2.5,0.2-5.5h-0.1l-6.1,18.5H2259.9z"
          fill={theme}
        />
      </g>
    </svg>
  );
};

export default logo;
